<template>
  <!-- 关于我们 -->
  <div class="xx_contactUs">
    <div class="xx_contactUs_bg">
      <div
        class="big"
        :style="{
          backgroundImage: 'url(' + lxData.lxyyImg + ')'
        }"
      >
        <img :src="H5_IMG_HOST + lxData.lxyyImg" alt="" style="width:100%" />
      </div>
    </div>
    <div class="contactUs-nav">
      <div class="current-title">
        <span>联系我们</span>
      </div>
      <ul class="current-lxfs">
        <li v-for="(el, i) in lxData.lxyyList" :key="i">
          <div class="li_icon zz_all_img">
            <img :src="el.value" alt="" style="height:100%" />
          </div>
          <div class="li_text">
            <span>{{ el.key }}</span>
            <div class="li_text_nav" v-html="lxData.lxyyList2[i].value"></div>
          </div>
        </li>
      </ul>
    </div>
    <div class="contactUs-nav">
      <div class="current-title">
        <span>线上预约</span>
      </div>
      <div class="current-hzyx">
        <div class="current-hzyx-left">
          <el-input
            placeholder="请输入您前往门店"
            v-model="formData.company"
            clearable
          >
          </el-input>
          <el-input
            placeholder="请输入您的手机号"
            v-model="formData.phone"
            clearable
          >
          </el-input>
          <el-input
            placeholder="请输入您的姓名"
            v-model="formData.username"
            clearable
          >
          </el-input>
          <el-input
            placeholder="请输入您到店时间"
            v-model="formData.email"
            clearable
          >
          </el-input>
        </div>
        <div class="current-hzyx-right">
          <el-input
            type="textarea"
            :placeholder="lxData.lxyyText"
            v-model="formData.msgcontent"
            maxlength="1000"
            :autosize="{ minRows: 10, maxRows: 10 }"
            show-word-limit
            style="resize:none;"
          >
          </el-input>
          <el-button type="primary" @click="submitTo">提交预约</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPageMsg,submitToData } from '@/api/api'
export default {
  components: {},
  data () {
    return {
      project: 0,
      lxData: {},
      formData: {
        company: '',
        phone: '',
        username: '',
        email: '',
        msgcontent: ''
      },
    }
  },
  watch: {},
  methods: {
    getData () {
      let that = this
      let id = sessionStorage.getItem(this.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          that.lxData = res.data.data.lxyy
        }
      })
    },
    submitTo () {
      submitToData(this.formData).then(res => {
        if (res.data.code == 1) {
          this.$message.success('预约成功')
          let obj = {
            company: '',
            phone: '',
            username: '',
            email: '',
            msgcontent: ''
          }
          this.formData = obj
          this.getData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.xx_contactUs {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f1f1f1;
  .xx_contactUs_bg {
    overflow: hidden;
    position: relative;
    .big {
      overflow: hidden;
      background-repeat: no-repeat;
      background-position: 70% center;
      background-size: cover;
      position: relative;
      width: 100%;
      height: 5rem;
      animation: scaleAnim 5s ease forwards;
      img {
        width: 100%;
        opacity: 0;
      }
    }
  }
  .contactUs-nav {
    padding-bottom: 80px;
    .current-lxfs {
      border-left: solid 1px #e6e6e6;
      box-sizing: border-box;
      width: 86%;
      margin: 0 auto;
      display: flex;
      li {
        width: 25%;
        border-right: solid 1px #e6e6e6;
        border-bottom: solid 1px #e6e6e6;
        border-top: solid 1px #e6e6e6;
        box-sizing: border-box;
        text-align: center;
        padding-bottom: 30px;
        .li_icon {
          height: 138px;
          margin-top: 45px;
        }
        .li_text {
          min-height: auto;
          font-size: 18px;
          color: #666666;
          margin-top: 40px;
          padding: 0 30px;
          line-height: 1.5;
          .li_text_nav {
            margin-top: 5px;
          }
        }
      }
    }
    .current-hzyx {
      width: 86%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .current-hzyx-left {
        width: 49%;
        .el-input {
          margin-bottom: 31px;
          /deep/input {
            padding: 30px;
            padding-left: 20px;
          }
        }
      }
      .current-hzyx-right {
        width: 49%;
        .el-textarea {
          /deep/textarea {
            padding: 20px;
          }
        }
        .el-button {
          width: 100%;
          margin-top: 33px;
          padding: 20px 0;
          span {
            font-size: 20px;
          }
        }
      }
    }
  }
}
@keyframes scaleAnim {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
</style>
